.user-profile {
  border-radius: 35px;
}

.column {
  display: flex;
  flex-flow: column nowrap;
  height: 600px;
  width: 800px;
  padding: 10px 20px 10px 20px;
  color: white;
}

.profile-info-row {
  margin-top: 25px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  height: 100px;
}

.stats-column {
  display: flex;
  flex-flow: column nowrap;
  justify-content: left;
  align-items: center;
  width: 80px;
  height: 60px;

  /* justify-content: flex-start; */
  /* flex-flow: row nowrap; */
  font-size: 20px;
  font-weight: bold;
  /* width: 200px; */
  /* height: 6vh; */
  padding-top: 5px;
}

.green-text {
  color: #00be60;
  padding: 8px;
  width: 150px;
  text-align: left;
}

.grey-text {
  font-size: 10px;
  color: grey;
  padding-left: 15px;
  text-align: left;
}
.nfts {
  display: flex;
  flex-flow: row nowrap;
  /* height: 300px; */
  gap: 20px;
  margin: 0px 20px 20px 0px;
}

.title-label {
  justify-content: flex-start;
  display: flex;
  flex-flow: row nowrap;
  font-size: 20px;
  font-weight: bold;
  /* width: 200px; */
  height: 6vh;
  padding-top: 5px;
}

@media only screen and (max-width: 700px) {
  .column {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    width: 100%;
    padding: 10px 5px 10px 5px;
    color: white;
  }

  .profile-info-row {
    margin-top: 15px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: start;
    align-items: start;
    height: 100px;
  }

  .stats-column {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    height: 30px;
    margin-top: 30px;
  }
  .nfts {
    display: flex;
    flex-flow: row nowrap;
    /* height: 300px; */
    gap: 20px;
    margin: 135px 5px 5px 10px;
  }
}
