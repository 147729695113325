.close-nav-button {
  position: absolute;
  right: 10px;
  top: 7.5px;
  color: #00be60;
}

.left-nav-button {
  position: absolute;
  left: 10px;
  top: 10px;
  color: #00be60;
}

.left-nav-button-disabled {
  position: absolute;
  left: 10px;
  top: 10px;
  color: #555756;
}

.right-nav-button-disabled {
  position: absolute;
  right: 53px;
  top: 10px;
  color: #555756;
}

.right-nav-button {
  position: absolute;
  right: 53px;
  top: 10px;
  color: #00be60;
}

.profile-picture {
  height: 70px;
  width: 70px;
  border-radius: 50%;
}

.modal-buttons-row {
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 130px;
  height: 40px;
  background-color: transparent;
  color: #00be60;
}
