/* css class of a column with three segment
 */
.column {
  display: flex;
  flex-direction: column;
  /* height: 100%; */
  color: white;
  height: 480px;
}

.top {
  height: 80px;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  height: 600px;
  margin: 10px 0px 10px 0px;
  /* height: 100vh; */
}

.left-side {
  width: 360px;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.left-label {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  font-size: 14px;
  font-weight: bold;
}

.bottom-row {
  display: flex;
  justify-content: space-between;
  /* height: 50px; */
  width: 320px;
  margin-top: 16px;
  height: 50px;
  align-items: center;
}
.bottom-column {
  display: flex;
  flex-flow: column nowrap;
}

.nft-title {
  font-size: 30px;
  color: white;
  justify-content: center;
  margin-top: 16px;
  font-weight: bold;
  height: 80px;
}

.left-side button {
  margin-top: 16px;
}

.right-side {
  margin-top: 35px;
  width: 480px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: stretch;
}

.button-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 180px;
}

.section {
  padding: 10px;
  border: 1px solid #817f7f;
  border-radius: 8px;
  margin-bottom: 10px;
  margin-right: 6px;
  color: white;
}

.left-section {
  padding: 10px;
  border: 1px solid #817f7f;
  border-radius: 8px;
  margin-bottom: 10px;
  margin-right: 6px;
  color: white;
  margin-left: 15px;
  margin-right: 20px;
}

.section-bottom {
  height: auto;
  margin-right: 10px;

  display: flex;
  flex-flow: column nowrap;
  /* TODO:: find a way to use auto height with overflow */
  max-height: calc(100vh - 90px - 90px - 280px);
  padding: 16px;
  border: 1px solid #817f7f;
  border-radius: 8px;
  margin-bottom: 16px;
  color: white;
  overflow: hidden;
  min-height: 150px;
}

.header {
  text-align: left;
  padding-bottom: 4px;
}

.scrollable {
  display: flex;
  /* height: 200px; */
  /* max-height: 400px; */
  flex-flow: column nowrap;

  /* calc(100vh - 90px - 90px - 380px); */
  /* padding: 16px; */
  /* border: 1px solid #817f7f; */
  /* border-radius: 8px; */
  /* margin-bottom: 16px; */
  color: white;
  overflow-y: auto;
  min-height: 100px;
}

.labels {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 6px;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.box {
  display: flex;
  flex: 1 1 31%;
  flex-flow: row nowrap;
  height: 60px;
  /* background-color: #eee; */
  border: 1px solid #817f7f;
  border-radius: 4px;
  margin: 8px;
  justify-content: space-between;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  margin-bottom: 8px;
}

.stats-label {
  display: flex;
  justify-content: space-around;
  margin: 10px;
  flex-flow: column nowrap;
}

.top-stats {
  display: flex;
  /* make text in this element stay in one line, not wrap */
  flex-wrap: nowrap;
  align-items: start;
  padding-left: 6px;
  color: grey;
  font-size: 12px;
  font-weight: 600;
  width: 60px;
}

.top-stats-long {
  display: flex;
  /* make text in this element stay in one line, not wrap */
  flex-wrap: nowrap;
  align-items: start;
  padding-left: 8px;
  color: grey;
  font-size: 12px;
  font-weight: 600;
  width: 100px;
}

.bottom-stats {
  display: flex;
  align-items: start;
  padding-left: 8px;
  color: white;
  font-size: 14px;
}

.image {
  /* object-fit: cover;
  width: 360px;
  height: 360px; */
  margin-top: 20px;
}

.row {
  display: flex;
  flex: row nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  text-align: left;
  border-bottom: 0.2px solid #3a3838;
  padding-bottom: 2px;
}

.green-text {
  color: #00be60;
  padding: 8px;
  width: 150px;
  text-align: left;
}

.grey-text {
  font-size: 10px;
  color: grey;
  padding-left: 15px;
  text-align: left;
}

.middle {
  text-align: left;
  width: 180px;
}

.owner-label {
  cursor: pointer;
  color: white;
}

.owner-label:hover {
  color: #00be60;
}

@media only screen and (max-width: 700px) {
  .container {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    height: 100%;
    /* height: 100vh; */
  }
  .mobile-center {
    display: flex;
    /* justify-content: center; */
    flex-flow: column nowrap;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    margin-top: 20px;
  }
  .right-side {
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: stretch;
  }

  .nft-title {
    font-size: 20px;
    color: white;
    justify-content: center;
    margin-top: 10px;
    font-weight: bold;
    height: 80px;
  }
  .image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    margin-top: 20px;
    border-radius: 10px;
  }

  .stats-label {
    display: flex;
    justify-content: space-around;
    margin: 10px;
    flex-flow: column nowrap;
  }

  .top-stats {
    display: flex;
    /* make text in this element stay in one line, not wrap */
    flex-wrap: nowrap;
    align-items: start;
    padding-left: 6px;
    color: grey;
    font-size: 12px;
    font-weight: 600;
    width: 60px;
  }

  .top-stats-long {
    display: flex;
    /* make text in this element stay in one line, not wrap */
    flex-wrap: nowrap;
    align-items: start;
    padding-left: 8px;
    color: grey;
    font-size: 12px;
    font-weight: 600;
    width: 100px;
  }

  .bottom-stats {
    display: flex;
    align-items: start;
    padding-left: 8px;
    color: white;
    font-size: 14px;
  }

  .left-stats {
    display: flex;
    /* make text in this element stay in one line, not wrap */
    flex-wrap: nowrap;
    align-items: center;
    padding-left: 1px;
    color: grey;
    font-size: 12px;
    font-weight: 600;
    width: 50px;
  }

  .left-stats-long {
    display: flex;
    /* make text in this element stay in one line, not wrap */
    flex-wrap: nowrap;
    align-items: start;
    justify-items: start;
    padding-left: 8px;
    color: grey;
    font-size: 12px;
    font-weight: 600;
    width: 100px;
  }

  .right-stats {
    display: flex;
    align-items: center;
    padding-left: 8px;
    justify-items: end;

    color: white;
    font-size: 14px;
  }

  .row {
    display: flex;
    flex: row nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    text-align: left;
    border-bottom: 0.2px solid #3a3838;
    padding-bottom: 2px;
  }

  .green-text {
    color: #00be60;
    padding: 8px;
    width: 150px;
    text-align: left;
  }

  .grey-text {
    font-size: 10px;
    color: grey;
    padding-left: 15px;
    text-align: left;
  }

  .middle {
    text-align: left;
    width: 180px;
  }

  .section {
    padding: 5px;
    border: 1px solid #817f7f;
    border-radius: 8px;
    margin-bottom: 6px;
    margin-right: 4px;
    color: white;
  }

  .left-section {
    padding: 10px;
    border: 1px solid #817f7f;
    border-radius: 8px;
    margin-bottom: 10px;
    margin-right: 6px;
    color: white;
    margin-left: 15px;
    margin-right: 20px;
    margin-top: 10px;
  }

  .box {
    display: flex;
    flex: 1 1 31%;
    width: 85%;
    flex-flow: row nowrap;
    height: 35px;
    /* background-color: #eee; */
    border: 1px solid #817f7f;
    border-radius: 4px;
    margin: 8px;
    justify-content: space-between;
  }
  .stats-row {
    display: flex;
    justify-content: space-around;
    margin: 10px;
    flex-flow: row nowrap;
  }
}
