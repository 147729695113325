.title-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.title-label {
  justify-content: left;
  display: flex;
  flex-flow: row nowrap;
  font-size: 20px;
  font-weight: bold;
  width: 350px;
  padding: 5px;
  margin: 10px;
}

.price-chart-component {
  height: max(calc(50vh - 60px), 200px);
  overflow-y: auto;
}

.twitter-widget {
  height: max(calc(50vh - 110px), 200px);
  overflow-y: auto;
}
