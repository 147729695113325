@media only screen and (min-width: 701px) {
  /* For mobile phones: */
  .container-header {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100vw;
    height: 100vh;
  }

  .container-columns {
    overflow: auto;
    width: 100%;
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
  }

  .column-left {
    width: 220px;
    background-color: #08080d;
  }

  .row-center-top {
    height: 320px;
    /* background-color: rgb(137, 191, 238); */
    transition: height 1s;
  }

  .row-center-top-active {
    /* background-color: rgb(137, 191, 238); */
    height: max(calc(100vh - 60px - 80px), 200px);
  }

  .row-center-bottom {
    /* height: max(calc(100vh - 60px - 320px), 200px); */
    /* background-color: fuchsia; */
  }

  .row-center-bottom-active {
    /* background-color: fuchsia; */
    height: 6%;
  }
  .column-center {
    width: 55%;
    /* background-color: red; */
  }

  .row-right-top {
    /* background-color: gold; */
    height: 48%;
  }

  .row-right-bottom {
    /* background-color: grey; */
    height: 48%;
  }

  .column-right {
    width: 25%;
    /* background-color: blue; */
  }

  .row-left-top {
    /* background-color: khaki; */
    height: 400px;
  }
  .row-left-bottom {
    /* background-color: salmon; */
    height: max(calc(100vh - 60px - 430px), 200px);
    overflow-y: auto;
  }
}

@media only screen and (max-width: 700px) {
  /* For desktop: */

  .container-columns {
    overflow: auto;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-around;
  }

  .row-center-top {
    height: 180px;
    /* background-color: rgb(137, 191, 238); */
  }

  .row-center-bottom {
    height: calc(100%);
    /* height: max(calc(100vh - 60px - 320px), 200px); */
    /* background-color: fuchsia; */
  }

  .column-center {
    width: 100%;
  }
}
