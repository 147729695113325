.nft-collection-tile {
  display: flex;
  flex-flow: column nowrap;
  height: 230px;
  width: 180px;
  min-width: 180px;
  align-items: center;
  justify-items: center;
  border-radius: 20px;
  border-style: solid;
  border-color: grey;
  color: white;
  font-size: large;
  font-family: "oxanium";
  font-weight: bold;
  border-width: 1px;
  background-color: #232323;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.nft-collection-tile-image {
  /* border-radius: 10px 10px 0 0; */
  height: 180px;
  width: 180px;
  /* padding: 5px; */
  object-fit: cover;
}

.nft-collection-number {
  position: absolute;
  left: 110px;
  top: 130px;
  background-color: #183e2b;
  color: #00d56e;
  font-size: 30px;
  width: 50px;
  font-weight: 900;
  height: 30px;
  border-radius: 5px;
  display: flex;
  align-content: center;
  justify-content: center;
}

@media only screen and (max-width: 700px) {
  .nft-collection-tile {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    height: 180px;
    width: 150px;
    min-width: 150px;
    align-items: center;
    justify-items: center;
    border-radius: 10px;
    border-style: solid;
    border-color: grey;
    color: white;
    font-size: large;
    border-width: 1px;
    background-color: #232323;
    overflow: hidden;
    cursor: pointer;
  }
  .nft-collection-tile-image {
    /* border-radius: 10px 10px 0 0; */
    height: 150px;
    width: 150px;
    position: relative;
    object-fit: cover;
  }

  .nft-collection-number {
    position: absolute;
    left: 50px;
    top: 5px;
    background-color: #183e2b;
    color: #00d56e;
    font-size: 15px;
    width: 30px;
    font-weight: 700;
    height: 20px;
    border-radius: 5px;
    display: flex;
    align-content: center;
    justify-content: center;
  }
  .name-label {
    font-size: 12px;
    margin-top: 4px;
    margin-bottom: 4px;
  }
}
