.activity-tile {
  display: flex;
  flex-flow: row nowrap;
  align-content: center;
  justify-content: space-between;
  background-color: aquamarine;
}
.title-label {
  justify-content: center;
  display: flex;
  flex-flow: row nowrap;
  font-size: 28px;
  font-weight: bold;
  width: 220px;
  padding: 5px;
  /* position: absolute; */
  /* bottom: 0; */

  /* background-color: blue; */
}

.middle {
  width: 200px;
}
.activity-component {
  /* position: relative; */
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
  height: calc(50vh - 115px);
  overflow: auto;
}

.transactions-table {
  position: -webkit-sticky;
  position: sticky;
  display: table;
  /* gap: 10px; */
  /* grid-template-columns: repeat(5, 100px [col-start]); */
  /* grid-template-columns: 50px 50px 150px 50px 50px; */
  /* display: flex;
	flex-flow: row wrap; */
  /* column-count: 5;
	column-width: auto;
	column-rule: 1px solid lightcoral;
	column-gap: 10px; */
}

.row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  width: 90%;
  min-width: 150px;
  margin: 5px;
  padding: 5px;
  /* border: 1px solid red; */
  color: white;
  background-color: #111518;
}

.date-col {
  display: flex;
  flex-flow: column nowrap;
  width: 80px;
  /* background-color: green; */
}

.green-text {
  color: #00be60;
  padding: 8px;
  width: 150px;
}

.grey-text {
  font-size: 10px;
  color: grey;
  padding-left: 15px;
}

@media only screen and (max-width: 700px) {
  .title-label {
    justify-content: start;
    display: flex;
    flex-flow: row nowrap;
    font-size: 22px;
    font-weight: bold;
    width: 220px;
    padding: 5px;
  }

  /* .middle {
    width: 200px;
  } */
  .activity-component {
    /* position: relative; */
    width: 100%;
    margin-top: 5px;
    margin-bottom: 10px;
    height: calc(100vh - 60px - 240px);
    overflow: auto;
  }
  .row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    width: 90%;
    min-width: 150px;
    margin: 5px;
    padding: 5px;
    /* border: 1px solid red; */
    color: white;
    background-color: #111518;
  }

  .date-col {
    display: flex;
    flex-flow: column nowrap;
    width: 90px;
    /* background-color: green; */
  }

  .green-text {
    display: flex;
    color: #00be60;
    padding-left: 3px;
    justify-content: start;
    width: 70px;
  }

  .grey-text {
    font-size: 8px;
    color: grey;
    padding-left: 3px;
    width: 70px;
  }
  /* .middle-mobile-part {
    display: flex;
    flex-flow: row nowrap;
    justify-content: left;
    align-items: center;
    width: 250px;
  } */
}
