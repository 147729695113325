.nft-collection-container {
  display: flex;
  flex-flow: row wrap;
  overflow-x: auto;
  gap: 20px;
  /* margin: 0px 20px 20px 20px; */
  /* max(calc(100vh - 60px - 80px), 200px); */
}

.nft-container {
  /* display: flex;
  flex-flow: row wrap;
  height: 100%;
  justify-content: space-evenly;
  overflow: auto;
  gap: 20px; */
  margin: 0px 10px 10px 10px;
  display: flex;
  flex-flow: row wrap;
  overflow: auto;
  height: calc(100vh - 230px);
  gap: 20px;
}

.nft-tile {
  display: flex;
  flex-flow: column nowrap;
  height: 220px;
  width: 180px;
  /* min-width: 160px; */
  align-items: center;
  justify-items: center;
  border-radius: 20px;
  border-style: solid;
  border-color: grey;
  color: white;
  font-size: large;
  border-width: 1px;
  background-color: #232323;
  overflow: hidden;
}
.nft-tile-image {
  /* margin-top: 5px; */
  /* border-radius: 20px 20px 0 0; */
  height: 180px;
  width: 180px;
  position: relative;
  object-fit: cover;
}

.nft-collection-number {
  position: absolute;
  left: 110px;
  top: 130px;
  background-color: #183e2b;
  color: #00d56e;
  font-size: 30px;
  width: 50px;
  font-weight: 900;
  height: 30px;
  border-radius: 5px;
  display: flex;
  align-content: center;
  justify-content: center;
}
.nft-collection-tile {
  display: flex;
  flex-flow: column nowrap;
  height: 230px;
  width: 180px;
  min-width: 180px;
  align-items: center;
  justify-items: center;
  border-radius: 20px;
  border-style: solid;
  border-color: grey;
  color: white;
  font-size: large;
  font-family: "oxanium";
  font-weight: bold;
  border-width: 1px;
  background-color: #232323;
  position: relative;
  overflow: hidden;
}
.nft-collection-tile-image {
  /* border-radius: 10px 10px 0 0; */
  height: 180px;
  width: 180px;
  /* padding: 5px; */
  object-fit: cover;
}

.title-label {
  justify-content: flex-start;
  display: flex;
  flex-flow: row nowrap;
  font-size: 28px;
  font-weight: bold;
  width: 200px;
  height: 6vh;
  padding-top: 5px;

  /* background-color: blue; */
}

/* .top-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  width: 54vw;
  margin: 5px;
  padding: 5px;
  color: white;
} */

.scroll-buttons-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  /* height: 30px; */
  width: 80px;
  margin: 5px;
  /* padding: 5px; */
  /* font-size: 16px; */
  /* border: 1px solid red; */
  color: #00d56e;
}

@media only screen and (max-width: 700px) {
  .nft-collection-number {
    position: absolute;
    left: 110px;
    top: 130px;
    background-color: #183e2b;
    color: #00d56e;
    font-size: 30px;
    width: 50px;
    font-weight: 900;
    height: 30px;
    border-radius: 5px;
    display: flex;
    align-content: center;
    justify-content: center;
  }
  .nft-collection-tile {
    display: flex;
    flex-flow: column nowrap;
    height: 230px;
    width: 180px;
    min-width: 180px;
    align-items: center;
    justify-items: center;
    border-radius: 20px;
    border-style: solid;
    border-color: grey;
    color: white;
    font-size: large;
    font-family: "oxanium";
    font-weight: bold;
    border-width: 1px;
    background-color: #232323;
    position: relative;
    overflow: hidden;
  }
  .nft-collection-tile-image {
    /* border-radius: 10px 10px 0 0; */
    height: 180px;
    width: 180px;
    /* padding: 5px; */
    object-fit: cover;
  }
  /* .nft-collection-container {
    display: flex;
    flex-flow: row wrap;
    overflow-x: auto;
    gap: 20px;
    /* margin: 0px 20px 20px 20px; */
    /* max(calc(100vh - 60px - 80px), 200px); */
  /*} */
}
