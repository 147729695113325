.container-tiles {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  /* align-content: flex-start; */
  gap: 10px;
  height: 100%;
  background-color: #08080d;
  color: #373f4a;
}

.tile {
  height: 80px;
  width: 80px;
  background-color: "#0E0F14";
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #0e0f15;
  border-radius: 5px;
}
.active-tile {
  background-color: #00d56e;
  /* in order: x offset, y offset, blur size, spread size, color */
  box-shadow: 0px 0px 10px 2px #00d56e;

  color: black;
}

.icon {
  width: 50px;
  height: 50px;
}
.container-with-button {
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
  height: 100%;
  background-color: #08080d;
  color: #373f4a;
}
