.header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: #222;
  width: 100%;
  height: 60px;
}
.header img {
  width: 25px;
  height: 25px;
  margin: 10px;
}

.button {
  vertical-align: middle;
  height: 42px;
  padding-right: 10px;
  padding-left: 10px;
  border: none;
  color: #00be60;
  background-color: white;
  font-family: "Oxanium";
  font-weight: 600;
  font-size: 15px;
  background-color: transparent;
  border-radius: 10px;
  cursor: pointer;
}

.eye-container {
  display: flex;
  padding-left: 5px;
  justify-content: center;
  height: 41px;
  width: 80px;
  /* padding: 10px; */
  border: 1px solid white;
  color: white;
  font-family: "Oxanium";
  font-weight: 600;
  font-size: 20px;
  align-items: center;
  /* background-color: #3396ff; */
  border-radius: 10px;
}

.box {
  width: 10px;
}

.row {
  color: white;
  display: flex;
  flex-flow: row nowrap;
  margin: 10px;
  align-items: center;
}

.profile-button {
  height: 42px;
  width: 120px;
  background-color: #00be60;
  color: white;
  font-size: 15px;
  font-weight: 600;
  border-radius: 10px;
  padding: 10px;
  border: none;
  color: white;
  font-family: "Oxanium";
  cursor: pointer;
}
/* 
@media only screen and (max-width: 700px) {
  .header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    background-color: #222;
    width: 100%;
    height: 40px;
  }
} */
