.row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  width: 210px;
  padding: 0px 40px 0px 0px;
  color: white;
}

.box {
  height: 20px;
}

.profile-button {
  height: 42px;
  width: 70%;
  margin-left: 10%;
  background-color: #00be60;
  color: white;
  font-size: 15px;
  font-weight: 600;
  border-radius: 10px;
  padding: 10px;
  justify-self: center;
  border: none;
  color: white;
  font-family: "Oxanium";
  cursor: pointer;
}

.sidenav {
  position: absolute;
  width: 250px;
  transition: width 0.3s ease-in-out;
  height: 100vh;
  background-color: rgb(66, 149, 238);
  padding-top: 28px;
}

.sidenavClosed {
  transition: width 0.3s ease-in-out;
  width: 0px;
  height: 0px;
}

.sideitem {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  color: #b2bac2;
  text-decoration: none;
}

.linkText {
  padding-left: 16px;
}

.linkTextClosed {
  composes: linkText;
  visibility: hidden;
}

.sideitem:hover {
  background-color: #244f7d1c;
}

.menuBtn {
  align-self: center;
  align-self: flex-start;
  justify-self: flex-end;
  color: #b2bac2;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding-left: 20px;
}
