.title-label {
  justify-content: center;
  display: flex;
  flex-flow: row nowrap;
  font-size: 28px;
  font-weight: bold;
  width: 220px;
  padding: 5px;
  /* position: absolute; */
  /* bottom: 0; */

  /* background-color: blue; */
}

::-webkit-scrollbar {
  scrollbar-width: none;
  display: none;
}

.transactions-component {
  /* position: relative; */
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
  /* height: calc(60vh - 120px); */
  height: max(calc(100vh - 60px - 400px), 200px);

  overflow: auto;
  /* .webkit-scrollbar {
    display: none;
  } */
}

.row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  width: 54vw;
  margin: 5px;
  padding: 5px;
  /* border: 1px solid red; */
  color: white;
  background-color: #111518;
}

.date-col {
  display: flex;
  flex-flow: column nowrap;
  min-width: 80px;
  font-weight: 800;
  /* background-color: green; */
}

.sale-to {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  min-width: 300px;
  /* background-color: blue; */
}

.price-col {
  display: flex;
  flex-flow: column nowrap;
  min-width: 120px;
  /* background-color: red; */
}

.sale-type {
  min-width: 80px;
  /* justify-content: left; */
  display: flex;

  /* align-content: flex-start; */
}

.collection {
  min-width: 80px;
  /* justify-content: left; */
  display: flex;

  /* align-content: flex-start; */
}

.detail-button {
  background-color: #00be60;
  border-radius: 4px;
  height: 30px;
  width: 80px;
  font-weight: bold;
  border: 0;
}

.filter-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  width: 54vw;
  margin: 5px;
  padding: 5px;
  font-size: 16px;
  /* border: 1px solid red; */
  color: white;
}

.multiselect-custom {
  --rmsc-main: #4285f4 !important;
  --rmsc-hover: #0e0c0a !important;
  --rmsc-selected: #1d1915 !important;
  --rmsc-border: #333333 !important;
  --rmsc-gray: #555555 !important;
  --rmsc-bg: #000000 !important;
  color: #fff !important;
}
