.community-container {
  /* display: flex;
  flex-flow: column nowrap;
  gap: 4px;
  justify-items: left;
  align-items: left;
  overflow-y: hidden;
  font-weight: 700;
  color: white;
  padding-top: 10px; */
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
  height: max(calc(100vh - 60px - 430px, 200px));
  /* height: calc(100%-900px); */
  overflow: auto;
  color: white;
}

span {
  display: inline-block;
  width: 180px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.title-label {
  justify-content: flex-start;
  display: flex;
  flex-flow: row nowrap;
  font-size: 28px;
  font-weight: bold;
  width: 220px;
  padding: 15px 5px 5px 10px;
}

.community-row {
  display: flex;
  align-items: left;
  margin: 10px;
  cursor: pointer;
}
